import React, { useEffect, useState } from 'react'
import Loader from './components/Loader';
import Schedules from './pages/Schedules';
import ErrorPage from './pages/ErrorPage';
import { connectId, connectOpt, authData } from './variables';
import { auth } from './services/api';

interface Render {
  canRender: any,
}

export default function App() {

  const [page, setPage] = useState<Render>({
    canRender: undefined,
  });
  const [userId, setUserId] = useState('')
  const [token, setToken] = useState('')


  useEffect(() => {
    oAuth()
  }, [])

  async function oAuth() {
    console.log("[oAuth] Initializing...");
    const platformClient = window.require("platformClient");
    const client = platformClient.ApiClient.instance;
    const { origin, protocol, host, pathname } = window.location;
    const redirectUrl = (origin || `${protocol}//${host}`) + pathname;

    console.log(`redirectUrl: ${redirectUrl}`);

    client.setEnvironment("mypurecloud.com");
    client.setPersistSettings(true, connectOpt);

    try {
      await client.loginImplicitGrant(connectId, redirectUrl, { state: "ok" })
      console.log(`[loginImplicitGrant] Authenticated!`);
      const authDataValue = localStorage.getItem(authData)
      const tokenValue = JSON.parse(authDataValue!)
      const userInstance = new platformClient.UsersApi();
      const userInfo = await userInstance.getUsersMe();

      let authBody = {
        id: userInfo.id,
        name: userInfo.name
      }

      const resData = await auth(authBody)

      if (resData.access_token) {
        localStorage.setItem("tokenBack", resData.access_token)
      }
      else {
        setPage({ canRender: false });
        return
      }
      setToken(tokenValue.accessToken)
      localStorage.setItem("user_id", resData.user.id)
      localStorage.setItem("user_name", resData.user.name)
      localStorage.setItem("user_title", resData.user.title)
      setUserId(resData.user.id);
      setPage({
        canRender: true,
      });
    }
    catch (error) {
      console.log(error)
      setPage({
        canRender: false,
      });
    };

  }

  async function reloadBtn() {
    console.log("Recarregando...")
    setPage({
      canRender: undefined,
    });
    await new Promise(resolve => {
      setTimeout(resolve, 15000)
    })
    await oAuth();
  }

  if (page.canRender === undefined) return <Loader />;
  else if (page.canRender === true) return <Schedules userId={userId} token={token} />
  else return <ErrorPage clicked={reloadBtn} />

}

