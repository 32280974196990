import React, { useEffect, useRef, useState } from 'react'
import FullCalendar, { EventApi, EventContentArg, DayHeaderContentArg, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import "./styles.scss"
import { getSchedules, listCheck } from '../../services/api'
import { manipulateDataEvent, eventDuration, formatDate, mouseOverInfos } from '../../utils/manipulateDataEvent'
import { ModalFilter } from '../../components/ModalFilters'
import Loader from '../../components/Loader'


type Props = {
  userId: string;
  token: string;
}

export default function Schedules({ userId, token }: Props,) {
  const [openModalFilter, setOpenModalFilter] = useState(false)
  const [nameSelected, setNameSelected] = useState<string | null>(null)

  const [event, setEvent] = useState<EventInput>([])
  const [list, setList] = useState([])
  const [searchId, setSearchId] = useState('')

  async function getUserSchedule(id: string, token: string) {

    try {
      const resSchedule = await getSchedules(id, token)
      if (resSchedule.length > 0) {
        if (resSchedule[0]._id) {
          const eventData = manipulateDataEvent(resSchedule)
          setEvent(eventData)
        }
        else {
          const emptyEvent: EventInput[] = [{}]
          setEvent(emptyEvent)
        }
      }
      else {
        const emptyEvent: EventInput[] = [{}]
        setEvent(emptyEvent)
      }
    } catch (error) {
      console.log(error)
      const emptyEvent: EventInput[] = [{}]
      setEvent(emptyEvent)
    }
  }

  async function getList() {
    const resList = await listCheck()
    if (resList) {
      setList(resList)
    }

  }


  useEffect(() => {
    getList()
    getUserSchedule(userId, token);
  }, [userId])

  useEffect(() => {
    setEvent([])
    if (searchId) {
      getUserSchedule(searchId, token)
    }
  }, [searchId])

  function renderEventContent(eventContent: EventContentArg) {
    const duration = eventDuration(eventContent.event.startStr, eventContent.event.endStr)
    return (
      <>
        <div className="scheduledEvent" title={mouseOverInfos(eventContent.event)}>
          <div className={`title ${duration <= 30 && 'shortEvent30'} ${duration <= 15 && 'shortEvent15'}`}>

            <p className='titleText'>
              {eventContent.view.type === 'dayGridMonth' &&
                <span className="eventColor" style={{ background: eventContent.backgroundColor }}></span>
              }
              <strong>{eventContent.event.title}</strong>
            </p>
            <p>{eventContent.timeText}</p>
          </div>
          <p><i>{eventContent.event.extendedProps?.description}</i></p>
        </div>
      </>
    )
  }

  const calendar = useRef<FullCalendar>(null)

  function customHeaderContent(headerContent: DayHeaderContentArg) {
    return (
      <>
        <div className="customDayWeek">
          <div className="numDay">{headerContent.text.slice(0, 3)}</div>
          {headerContent.view.type !== 'dayGridMonth' &&
            <div className={`weekDay ${headerContent.isToday && 'isToday'}`}>{headerContent.text.slice(-2)}</div>
          }
        </div>
      </>
    )
  }

  const startDay = () => {
    const today = new Date();
    if (today.getDay() > 0) {
      return new Date(today.getTime() - (86400000 * today.getDay()))
    } else {
      return today;
    }
  };

  const fourWeeksNext = new Date(startDay().getTime() + (86400000 * 28))

  return (
    <div className="container">
      {event.length ? (
        <div className="calendar">
          <FullCalendar
            locale={'br'}
            initialView='timeGridWeek'
            validRange={{
              start: formatDate(startDay()),
              end: formatDate(fourWeeksNext)
            }}
            ref={calendar}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today customButton',
              center: 'title',
              right: 'refreshButton dayGridMonth,timeGridWeek'
            }}

            customButtons={{
              customButton: {
                text: `${nameSelected || 'Pesquisar'}`,
                click: () => setOpenModalFilter(true)
              },
              refreshButton: {
                text: 'Atualizar',
                click: function(){
                  getList()
                  if (searchId) {
                    setEvent([])
                    getUserSchedule(searchId, token)
                  }
                  else {
                    setEvent([])
                    getUserSchedule(userId, token);
                  }
                }
              }
            }}

            buttonText={{
              today: 'hoje',
              month: 'mês',
              week: 'semana',
              day: 'dia',
              list: 'lista'
            }}
            timeZone="local"
            expandRows={true}
            aspectRatio={2}
            slotMinTime={'07:00:00'}
            slotMaxTime={'22:00:00'}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              meridiem: 'lowercase'
            }}
            dayHeaderFormat={{ weekday: 'short', day: 'numeric' }}
            nowIndicator={true}
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={true}
            weekends={true}
            initialEvents={event}
            eventContent={renderEventContent}
            dayHeaderContent={customHeaderContent}
          />
          {openModalFilter &&
            <ModalFilter setOpenModal={setOpenModalFilter} setData={setSearchId} data={list} nameSelected={setNameSelected} />
          }
        </div>
      ) : <Loader />}
    </div>
  );
}

