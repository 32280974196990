import { dbHost } from '../variables'
import axios from 'axios';


export const auth = async (userInfo: any) => {
    try {
        const res = await axios.post(`${dbHost}/auth/`, userInfo)
        return res.data
    } catch (error: any) {
        return error
    }
}

const api = axios.create({
    baseURL: dbHost,
})

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("tokenBack")
        if (token) {
            if (config.headers) {
                config.headers.Authorization = "Bearer " + token
            }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);

api.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    async (error) => {
        if (!error.response) {
            return error.response
        }

        if (error.response.status === 401) {
            localStorage.removeItem("tokenBack")
        }
        return error.response

    })

export default api;



export const getSchedules = async (id: string, token: string) => {
    try {
        const res = await api.post(`/schedule/user/${id}`, { token: token })
        return res.data
    } catch (error: any) {
        return error
    }
}

export const listCheck = async () => {
    try {
        const res = await api.get(`/user/list`)
        return res.data
    } catch (error: any) {
        return error
    }
}


