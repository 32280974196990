import React from "react";
import styles from "./ErrorPage.module.scss";

export default function ErrorPage(props: any) {
  return (
    <div className={styles.errorTemplate}>
      <h1>Oops!</h1>
      <h2>Aconteceu um erro.</h2>
      <div>Erro na autenticação.</div>
      <button onClick={props.clicked} className={styles.btn}>
        Recarregar
      </button>
    </div>
  );
}