import { EventInput } from '@fullcalendar/react'

export function manipulateDataEvent(data: any) {
    let events: EventInput[] = []
    let eventGuid = 0;
    for (let i = 0; i < data.length; i++) {
        data[i].activities.map(function (e: any) {

            let startDate = new Date(e.startDate);
            startDate.setHours(startDate.getHours() - 3);
            let endDate = new Date(startDate.getTime() + (e.lengthMinutes * 60000));
            const color = backgroundColor(e.activityCodeId)
            events.push({
                id: String(eventGuid++),
                title: titleByGroupId(e.activityCodeId),
                start: startDate.toISOString().replace('0Z', ''),
                end: endDate.toISOString().replace('0Z', ''),
                backgroundColor: color.background,
                textColor: color.text,
                description: `${data[i].businessUnit_name}: ${e.description || ""}`,
                groupId: e.activityCodeId
            })
        })
    }
    return events
}

function backgroundColor(activityCodeId: string) {
    switch (activityCodeId) {
        case "0":
            return { background: "#b5eafc", text: "#3299bf" }
        case "1":
            return { background: "#e1c4ae", text: "#807965" }
        case "2":
            return { background: "#f6e2ac", text: "#88905d" }
        case "3":
            return { background: "#f8b0b0", text: "#883243" }
        case "4":
            return { background: "#d0aea8", text: "#7e6967" }
        case "5":
            return { background: "#edc1f0", text: "#7c53c0" }
        case "6":
            return { background: "#94debf", text: "#09aa84" }
        case "7":
            return { background: "#cfcfcf", text: "#5e8798" }
        default: 
            return { background: "#3788d8", text: "#FFFFFF" }
    }
}

export function eventDuration(start: string, end: string) {
    return ((Date.parse(end) - Date.parse(start)) / 1000) / 60;
}

export function titleByGroupId(groupId: string) {
    switch (groupId) {
        case "0":
            return "Fila"
        case "1":
            return "Intervalo"
        case "2":
            return "Refeição"
        case "3":
            return "Reunião"
        case "4":
            return "Fora da fila"
        case "5":
            return "Folga"
        case "6":
            return "Treinamento"
        case "7":
            return "Não disponível"
        default: 
            return ""
    }
}

export function mouseOverInfos(activities: any) {
    if(!activities.groupId){
        return ""
    } else {
        let id = activities.groupId
        const {messageOne, messageTwo} = formatHour(activities.start, activities.end)
        const messageFull = `\n${messageOne} \nDuração: ${messageTwo} minutos \n${activities.extendedProps?.description || ''}`
        switch (id) {
            case "0":
                return `Fila ${messageFull}`
            case "1":
                return `Intervalo ${messageFull}`
            case "2":
                return `Refeição ${messageFull}`
            case "3":
                return `Reunião ${messageFull}`
            case "4":
                return `Fora da fila ${messageFull}`
            case "5":
                return `Folga ${messageFull}`
            case "6":
                return `Treinamento ${messageFull}`
            case "7":
                return `Não disponível ${messageFull}`
            default: 
                return ""
        }
    }
}

export function formatHour(start: Date, end: Date){
    const startDate = new Date(start);
    const endDate = new Date(end)
    const messageOne = `${startDate.getHours()}:${startDate.getMinutes()} a ${endDate.getHours()}:${endDate.getMinutes()}`
    const messageTwo = (endDate.getTime() - startDate.getTime())/60000
    return { messageOne, messageTwo};
}

export function formatDate(date: Date) {
    return date.getFullYear() + "-" + (date.getMonth() > 8 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
}