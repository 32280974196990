import { useRef, useState } from 'react';
import styles from './styles.module.scss'

type DataProps = {
    businessUnit_id: string;
    name: string;
    title: string;
    __v: number;
    _id: string;
}

type Props = {
    setOpenModal: (value: boolean) => void;
    setData: Function
    data: DataProps[];
    nameSelected: (value: string) => void;
}

export const ModalFilter = ({ setOpenModal, setData, data, nameSelected }: Props) => {
    const [inputFocus, setInputFocus] = useState(false)
    const [search, setSearch] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [idSearch, setIdSearch] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)

    const handleInputValue = (name: string, id: string) => {
        inputRef.current && (inputRef.current.value = name);
        setInputValue(name);
        setIdSearch(id)
        setInputFocus(false);
    }

    const handleSubmit = () => {
        nameSelected(inputValue);
        setOpenModal(false);
        setData(idSearch)
    }
   
    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    {data.length ? (<h1>Pesquisar agentes</h1>) : (<h1>Função disponível apenas para supervisores.</h1>)}

                    <button className={styles.closeModal} onClick={() => setOpenModal(false)}></button>

                </div>
                {data.length ? (
                    <div className={styles.content}>
                        <div className={styles.group}>
                            <input
                                ref={inputRef}
                                type="text"
                                onFocus={() => setInputFocus(true)}
                                onChange={(e) => setSearch(e.target.value)}
                                required
                            />
                            <span className={styles.highlight}></span>
                            <span className={styles.bar}></span>
                            <label className={`${inputValue.length && styles.active}`}>Nome</label>
                        </div>
                        <div
                            className={styles.list}
                            style={{
                                display: inputFocus ? "block" : "none"
                            }}
                        >
                            <ul>
                                {data.map(item => (item.name.toLocaleLowerCase()).includes(search.toLocaleLowerCase()) ? (
                                    <li 
                                        key={item._id} 
                                        onClick={() => {handleInputValue(item.name, item._id)}}
                                    > 
                                        {item.name} 
                                    </li>
                                ) : null)}
                            </ul>
                        </div>

                        <button onClick={handleSubmit}>OK</button>
                    </div>
                ) : ('')}

            </div>
        </div>
    )
}